import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../config/axios";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import classes from "./korisnickiPaketiSinglePage.module.css";
import prethodna from "./assets/prethodna.png";
import sledeca from "./assets/sledeca.png";
import dole from "./assets/dole.png";
import gore from "./assets/gore.png";
import vasipredlozistrelica from "./assets/vasipredlozistrelica.png";
import React from "react";
import ReactPlayer from "react-player";
import { Image, Modal } from "antd";

const handleContextMenu = (event) => {
  event.preventDefault();
};

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const KorisnickiPaketiSinglePage = () => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [previousPost, setPreviousPost] = useState({});
  const [nextPost, setNextPost] = useState({});
  const [threeBefore, setThreeBefore] = useState({});
  const [threeNext, setThreeNext] = useState({});
  const [chatImage, setChatImage] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [imageLink, setImageLink] = useState();

  const fetchData = async () => {
    const { data } = await axios.get(`/users-package/${id}`);

    // console.log(data);

    setData(data.post);
  };

  useEffect(() => {
    fetchData();
    fetchChatImage();
  }, [id]);

  const fetchChatImage = async () => {
    const img = await axios.get(`/chat-image/userspackages`);

    setChatImage(img.data);
  };

  const fetchPreviosuCard = async () => {
    // console.log(data._id);
    try {
      const previousPost = await axios.get(
        `/users-package-index?id=${data._id}&&index=-1`
      );
      setPreviousPost(previousPost.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNextCard = async () => {
    try {
      // console.log(data._id);
      const nextCard = await axios.get(
        `/users-package-index?id=${data._id}&&index=+1`
      );
      setNextPost(nextCard.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchThreeNextCard = async () => {
    try {
      // console.log(data._id);
      const threenextCard = await axios.get(
        `/users-package-index?id=${data._id}&&index=+3`
      );

      setThreeNext(threenextCard.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchThreeBeforeCard = async () => {
    try {
      // console.log(data._id);
      const threebeforecard = await axios.get(
        `/users-package-index?id=${data._id}&&index=-3`
      );

      setThreeBefore(threebeforecard.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleZoomInImage = (e) => {
    setSelectedImage(e);

    setIsModalVisible(true);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };


  return (
    <div className={classes.mainCenter}>
      {!data ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.content}>
          <div className={classes.contentRow}>
            {data &&
              data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => {
                if (item.type === "text") {
                  return (
                    <div
                      style={{
                        color: item.color,
                        fontWeight: item.bold ? "bolder" : false,
                        fontStyle: item.italic ? "italic" : false,
                        fontSize: item.fontSize ? `${item.fontSize}px` : "30px",
                        marginTop: item.marginTop,
                        marginBottom: item.marginBottom,
                      }}
                    >
                      {item.description}
                    </div>
                  );
                }
                if (item.type === "video") {
                  // console.log(item);
                  return (
                    <video
                      width="720"
                      height="360"
                      controls
                      controlsList="nodownload"
                      style={{ marginBottom: "10px" }}
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type="video/mp4"
                      />
                    </video>
                  );
                }
                if (item.type === "img") {
                  return (
                    <img
                      className={classes.itemImage}
                      src={`${SERVER_URL}/${item.image?.url}`}
                      onClick={() => {
                        handleZoomInImage(item.image?.url);
                        setImageLink(item.imageLink)
                      }}
                    ></img>
                  );
                }
              })}
          </div>

          <div className={classes.textField}>
            {data.textField?.toUpperCase()}
          </div>
          <Modal
            width="80%"
            height="auto"
            open={isModalVisible}
            onCancel={onCancel}
            footer={[]}
          >
            <a href={`${imageLink}`} target="_blank" rel="noopener noreferrer">
              <img style={{ width: "100%" }} src={`${SERVER_URL}/${selectedImage}`}/>
            </a>

          </Modal>
        </div>
      )}
    </div>
  );
};

export default KorisnickiPaketiSinglePage;
