import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from '../config/axios'
import Spinner from '../components/Spinner'
import classes from './infoBlockSinglePage.module.css'
import Comments from '../components/Comments'
import React from 'react'
import ReactPlayer from 'react-player'
import AuthContext from '../context/AuthProvider'
import SideImages from '../components/Redesign/SideImages/SideImages'
import CalculatorImage from '../components/Redesign/CalculatorImage/CalculatorImage'
import RecommendedPosts from '../components/Redesign/RecommendedPosts/RecommendedPosts'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const InfoBlokSinglePage = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)

  const [data, setData] = useState({})
  const [chatImage, setChatImage] = useState()
  const [previousPost, setPreviousPost] = useState({})
  const [nextPost, setNextPost] = useState({})
  const [threeBefore, setThreeBefore] = useState({})
  const [threeNext, setThreeNext] = useState({})
  const [commentCounter, setCommentCounter] = useState({
    totalComments: 0,
    commentsLength: 0,
    answersLength: 0,
  })
  const [emailNotification, setEmailNotification] = useState(false)
  const [commentNotification, setCommentNotification] = useState(false)

  const fetchData = async () => {
    const { data } = await axios.get(`/info-block-post/${id}`)

    setData(data.post)
  }

  useEffect(() => {
    fetchData()
    fetchChatImage()
  }, [id])

  useEffect(() => {
    fetchPreviosuCard()
    fetchNextCard()
    fetchThreeNextCard()
    fetchThreeBeforeCard()
  }, [data])

  useEffect(() => {
    setEmailNotification(auth?.emailNotification)
    setCommentNotification(auth?.commentNotification)
  }, [])

  const fetchChatImage = async () => {
    const img = await axios.get(`/chat-image/infoblock`)

    setChatImage(img.data)
  }

  const fetchPreviosuCard = async () => {
    try {
      const previousPost = await axios.get(
        `/info-block-index?id=${data._id}&&index=-1`
      )
      setPreviousPost(previousPost.data)
    } catch (error) {}
  }

  const fetchNextCard = async () => {
    try {
      const nextCard = await axios.get(
        `/info-block-index?id=${data._id}&&index=+1`
      )
      setNextPost(nextCard.data)
    } catch (error) {}
  }

  const fetchThreeNextCard = async () => {
    try {
      const threenextCard = await axios.get(
        `/info-block-index?id=${data._id}&&index=+3`
      )

      setThreeNext(threenextCard.data)
    } catch (error) {}
  }

  const fetchThreeBeforeCard = async () => {
    try {
      const threebeforecard = await axios.get(
        `/info-block-index?id=${data._id}&&index=-3`
      )

      setThreeBefore(threebeforecard.data)
    } catch (error) {}
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  const handleEmailNotification = async (e) => {
    const { checked } = e.target

    setEmailNotification(checked)

    const payload = {
      emailNotification: checked,
      postId: id,
    }
    try {
      await axios
        .post(
          `${SERVER_URL}/user/setEmailNotification/${auth.email}`,
          payload,
          { withCredentials: false }
        )
        .then((res) => {
          window.location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCommentEmailNotification = async (e) => {
    const { checked } = e.target

    setCommentNotification(checked)

    const payload = {
      commentNotification: checked,
    }
    try {
      await axios
        .post(
          `${SERVER_URL}/user/setCommentNotification/${auth.email}`,
          payload,
          { withCredentials: false }
        )
        .then((res) => {
          window.location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const emailCheckedForPost =
    auth.emailNotificationPosts && auth.emailNotificationPosts.includes(id)
  const adminCommentOnly = data.adminCommentOnly ? data.adminCommentOnly : false

  return (
    <div className={`${classes.container} ${classes.infoBlockContainer}`}>
      <SideImages />
      <div className={classes.postsPageContainer}>
        <h1>Info Blok</h1>

        <div className={classes.postsAndCalculatorWrapper}>
          <RecommendedPosts />
          <div className={classes.postsContainer}>
            <div className={classes.optionsAndMarketing}>
              <h2 className={classes.postTitle}>{data.title?.toUpperCase()}</h2>
              <div className={classes.marketingMediaWrapper}>
                {data && data?.featureVideo && data?.featureVideo?.url ? (
                  <div className={classes.featureVideoDiv}>
                    <ReactPlayer
                      url={`${SERVER_URL}/${data.featureVideo?.url}`}
                      playing={true} // Enables autoplay
                      muted={true} // Mutes the video
                      loop
                      width='337px'
                      height='267px'
                      onContextMenu={handleContextMenu}
                    />
                  </div>
                ) : (
                  <img
                    className={classes.featureImage}
                    src={`${SERVER_URL}/${data.featureImage?.url}`}
                  ></img>
                )}

                {data && data.marketingVideo && data.marketingVideo.url ? (
                  <div className={classes.featureVideoDiv}>
                    <a
                      className={classes.marketingImageATag}
                      href={data.marketingLink}
                      target='_blank'
                    >
                      <ReactPlayer
                        url={`${SERVER_URL}/${data.marketingVideo?.url}`}
                        playing={true} // Enables autoplay
                        muted={true} // Mutes the video
                        loop
                        width='337px'
                        height='267px'
                        onContextMenu={handleContextMenu}
                      />
                    </a>
                  </div>
                ) : (
                  <a
                    className={classes.marketingImageATag}
                    href={data.marketingLink}
                    target='_blank'
                  >
                    <img
                      className={classes.marketingImage}
                      src={`${SERVER_URL}/${data.marketingImage?.url}`}
                    ></img>
                  </a>
                )}
                <a
                  className={classes.marketingImageATag}
                  href={chatImage?.link}
                  target='_blank'
                >
                  <img
                    className={classes.marketingImage}
                    src={`${SERVER_URL}/${chatImage?.image?.url}`}
                  ></img>
                </a>
              </div>
              <div className={classes.postOptions}>
                <button
                  onClick={() => navigate(`/info_blok/${previousPost._id}`)}
                >
                  <img src='/icons/arrow-up-single-post.svg' />
                  <span>Prošla kartica</span>
                </button>
                <button onClick={() => navigate(`/info_blok/${threeNext._id}`)}>
                  <img src='/icons/arrow-up-single-post.svg' />
                  <span>Kartica gore</span>
                </button>
                {/* TODO: ADD LINK */}
                <button>
                  <img src='/icons/lightning.svg' />
                  <span>Tipuj</span>
                </button>
                <button
                  onClick={() => navigate(`/info_blok/${threeBefore._id}`)}
                >
                  <img src='/icons/arrow-up-single-post.svg' />
                  <span>Kartica dole</span>
                </button>
                <button onClick={() => navigate(`/info_blok/${nextPost._id}`)}>
                  <img src='/icons/arrow-up-single-post.svg' />
                  <span>Sledeća kartica</span>
                </button>
              </div>
              <div className={classes.commentCounterContainer}>
                <div className={classes.commentCounter}>
                  <span className={classes.counterLabel}>Broj komentara: </span>
                  <span>{commentCounter.totalComments}</span>
                </div>
                <div className={classes.commentCounter}>
                  <span className={classes.counterLabel}>Broj odgovora: </span>
                  <span>{commentCounter.answersLength}</span>
                </div>
              </div>
              <div className={classes.leaveCommentContainer}>
                <p>Ostavite komentar na ovu temu</p>

                <button className={classes.leaveCommentButton}>
                  <img
                    src='/icons/comments/commentsCloudMessage.svg'
                    alt='ostavite komentar'
                  />
                  Ostavite komentar
                </button>
              </div>
              <div className={classes.emailNotificationsContainer}>
                <div className={classes.emailNotifications}>
                  <input
                    disabled={auth && Object.keys(auth).length < 1}
                    type='checkbox'
                    checked={emailCheckedForPost}
                    name='obavestenja'
                    id='obavestenja'
                    defaultChecked={true}
                    onChange={(e) => handleEmailNotification(e)}
                  />
                  <label htmlFor='emailNotifications'>
                    Želim da primam email obaveštenja za sve promene na ovoj
                    stranici
                  </label>
                </div>

                <div className={classes.emailNotifications}>
                  <input
                    disabled={auth && Object.keys(auth).length < 1}
                    type='checkbox'
                    checked={commentNotification}
                    name='commentObavestenje'
                    id='commentObavestenje'
                    onChange={(e) => handleCommentEmailNotification(e)}
                  />
                  <label htmlFor='commentNotifications'>
                    Želim da primam obaveštenja o odgovorima na komentare putem
                    emaila
                  </label>
                </div>
              </div>
            </div>
            {!data ? (
              <div className={classes.spinner}>
                <Spinner />
              </div>
            ) : (
              <Comments
                commentType={'info_blok'}
                adminComment={adminCommentOnly}
                user={auth}
                id={id}
                title={data.title}
                setCommentCounter={setCommentCounter}
              />
            )}
          </div>
          <div className={classes.calculatorContainer}>
            <CalculatorImage maxHeight={280} maxWidth={464} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBlokSinglePage
