import React, { useEffect, useState } from 'react'
import HomeLayout from '../../layout/HomeLayout'
import BetkoShopHomeNew from './BetkoShopHomeNew'
import classes from './BetkoShopLayout.module.css'
import ShopHeaderNew from '../../components/BetkoShop/ShopHeaderNew'
import LoginModalNovo from '../../components/LoginModalNovo'
import Modal from 'antd/es/modal/Modal'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const BetkoShopLayout = ({ children }) => {
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const [searchTerm, setSearchTerm] = useState(searchParams.get('searchTerm'))
  const navigate = useNavigate()
  const location = useLocation()

  const onChangeSearchTerm = (e) => {
    const newSearchTerm = e.target.value
    setSearchTerm(newSearchTerm)
    const params = new URLSearchParams(searchParams)
    params.set('searchTerm', newSearchTerm)

    if (location.pathname !== '/betko_shop/shop') {
      navigate(`/betko_shop/shop?${params.toString()}`, {
        state: { from: 'nonShopPage' },
      })
    } else {
      setSearchParams(params)
    }
  }

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 768)
  //   }

  //   window.addEventListener('resize', handleResize)
  //   return () => window.removeEventListener('resize', handleResize)
  // }, [])

  {
    return (
      <>
        <HomeLayout
          betkoShop={
            <div className={classes.betkoShopLayout}>
              <ShopHeaderNew
                setIsOpenLoginModal={setIsOpenLoginModal}
                onChangeSearchTerm={onChangeSearchTerm}
                searchTerm={searchTerm}
              />
              {children}
            </div>
          }
        />
        <LoginModalNovo
          setIsOpenLoginModal={setIsOpenLoginModal}
          isOpenLoginModal={isOpenLoginModal}
          title='Dobro došli na BETKO SHOP!'
        />
      </>
    )

    return (
      <div className={classes.betkoShopLayout}>
        <ShopHeaderNew
          setIsOpenLoginModal={setIsOpenLoginModal}
          onChangeSearchTerm={onChangeSearchTerm}
          searchTerm={searchTerm}
        />
        {children}
        <LoginModalNovo
          setIsOpenLoginModal={setIsOpenLoginModal}
          isOpenLoginModal={isOpenLoginModal}
          title='Dobro došli na BETKO SHOP!'
        />
      </div>
    )
  }
}

export default BetkoShopLayout
