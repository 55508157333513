import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './shopHeaderNew.module.css'
import { MdHome } from 'react-icons/md'
import { FaBalanceScaleLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../../context/AuthProvider'

const ShopHeaderNew = ({
  setIsOpenLoginModal,
  onChangeSearchTerm,
  searchTerm,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useContext(AuthContext)
  const isLogged = auth && Object.keys(auth).length > 0
  const searchTermRef = useRef(null)
  const from = location?.state?.from

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getClassNameForPath = (currentPath) => {
    return location?.search?.includes(currentPath) ||
      location?.pathname === '/betko_shop'
      ? classes['selectedMenuItem']
      : ''
  }

  return (
    <div className={classes.shopHeader}>
      <div className={classes.header}>
        <h1>BETKO SHOP</h1>
        <img src='/icons/shop/shoping-cart.svg' alt='Korpa' />
      </div>
    </div>
  )
}

export default ShopHeaderNew
