import React, { useEffect, useState } from 'react'
import classes from './SideImages.module.css'
import AdVideoPlayer from '../../AdVideoPlayer';

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const placeholderAdPath = "/tiger.webp";

const SideImages = () => {
  const [ads, setAds] = useState([]);

  useEffect(() => {
    const storedAds = JSON.parse(localStorage.getItem("selectedAds")) || [null, null, null];
    setAds(storedAds);
  }, []);

  const handleVideoClick = (ad) => {
    if (ad?.url) {
      window.open(ad.url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className={classes.sideImages}>
      {ads.map((ad, index) => (
        <div className={classes.adItem} key={index}>
          {ad ? (
            ad.type === "video" ? (
              <div onClick={() => handleVideoClick(ad)}>
                <AdVideoPlayer src={`${SERVER_URL}/${ad.localPath}`} />
              </div>
            ) : (
              <a href={ad.url} target="_blank" rel="noopener noreferrer">
                <img src={`${SERVER_URL}/${ad.localPath}`} alt="Ad" />
              </a>
            )
          ) : (
            <img src={placeholderAdPath} alt="Ad" />
          )}
        </div>
      ))}
    </div>
  );
};

export default SideImages
