import classes from './mojNalogNew.module.css'
import useLogout from '../hooks/useLogout'
import { useNavigate, Link } from 'react-router-dom'
import { useState, useContext, useEffect } from 'react'
import AuthContext from '../context/AuthProvider'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import jwt_decode from 'jwt-decode'
import { format } from 'date-fns'
import Spinner from '../components/Spinner'
import { Flex, notification } from 'antd'
import { IoStarSharp } from 'react-icons/io5'
import axios from '../config/axios'
import useRefreshToken from '../hooks/useRefreshToken'
import LoginPageImage from '../components/Redesign/LoginPageImage/LoginPageImage'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import Column from 'antd/es/table/Column'

const MojNalog = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  const [createdAt, setCreatedAt] = useState(0)
  const [paidToDate, setPaidToDate] = useState(0)
  const [changeTeam, setChangeTeam] = useState(false)
  const [changeUsername, setChangeUsername] = useState(false)
  const [teamValue, setTeamValue] = useState({})
  const [usernameValue, setUserNameValue] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const [confirmNewPasswordVisible, setConfirmNewPasswordVisible] =
    useState(false)

  const [isError, setIsError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const refresh = useRefreshToken()

  const [allTeams, setAllTeams] = useState([])

  const logout = useLogout()

  const [isEditing, setIsEditing] = useState(false)

  const [confirmedNewPassword, setConfirmedNewPassword] = useState('')

  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)
  const axiosPrivate = useAxiosPrivate()

  const rp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/

  const validOldPassword = rp.test(oldPassword)
  const validNewPassword = rp.test(newPassword)

  let email
  if (auth?.accessToken) {
    const token = auth?.accessToken
    const decoded = jwt_decode(token)
    email = decoded?.UserInfo?.email
  }
  let maskedPassword
  if (auth?.accessToken) {
    const token = auth?.accessToken
    const decoded = jwt_decode(token)
    const password = decoded?.UserInfo?.password

    if (password) {
      maskedPassword = '*'.repeat(password.length)
    }
  }

  const fetchTeams = async () => {
    try {
      await axios
        .get(`/getAllTeams`, { withCredentials: false })
        .then((res) => {
          if (res.status === 200) {
            setAllTeams(res.data.data)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTeams()

    return () => {}
  }, [])

  const signout = async () => {
    await logout()
    navigate('/')
  }

  const getUserInfo = async () => {
    const { data } = await axiosPrivate.get(`/user/${email}`)
    // console.log("data: ", data);
    setCreatedAt(format(new Date(data?.createdAt), 'dd-MM-yyyy'))
    setPaidToDate(format(new Date(data?.paidToDate), 'dd-MM-yyyy'))
    if (data.team) {
      setTeamValue(data?.team)
    } else {
      setTeamValue({})
    }
    if (data.userName) {
      setUserNameValue(data.userName)
    } else {
      setUserNameValue('')
    }
    return data
  }

  const userInfoQuery = useQuery({
    queryKey: ['users'],
    queryFn: getUserInfo,
    // staleTime: 0,
    // refetchInterval: 1000 * 60 * 60 * 12,
  })

  if (userInfoQuery.isError) {
    navigate('/registracija_i_logovanje')
  }

  const handleChangeTeam = (value) => {
    setTeamValue(value)
  }

  const handleUpdateTeam = async (value) => {
    if (!value || value === 'none') {
      notification.error({
        message: 'Izaberite tim.',
        placement: 'topRight',
      })
      return
    }

    const payload = { team: value }

    try {
      const res = await axiosPrivate.post(
        `/user/setUserTeam/${email}`,
        payload,
        {
          withCredentials: false,
        }
      )

      if (res.status === 201) {
        setChangeTeam(false)

        notification.success({
          message: 'Uspešno promenjen tim.',
          placement: 'topRight',
        })
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: error.response?.data?.message || 'Greška pri ažuriranju tima.',
        placement: 'topRight',
      })
    }
  }

  const onChangeUsername = (e) => {
    const { value } = e.target
    if (value.includes(' ')) {
      return
    }
    setUserNameValue(e.target.value)
  }

  const handleUpdateUserName = async (e) => {
    if (!usernameValue || usernameValue === '') {
      notification.error({
        message: 'Unesite username',
        placement: 'topRight',
      })
      return
    }

    const payload = {
      userName: usernameValue,
    }

    try {
      await axiosPrivate
        .post(`/user/setUserName/${email}`, payload, { withCredentials: false })
        .then((res) => {
          if (res.status === 201) {
            setChangeUsername(false)

            // notification
            notification.success({
              message: 'Uspešno promenjen username.',
              placement: 'topRight',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          // notification
          notification.error({
            message:
              error.response.data.message || 'Error while updating user.',
            placement: 'topRight',
          })
        })
    } catch (error) {
      console.error(error)
      // notification
      notification.error({
        message: error.response.data.message || 'Error while updating user.',
        placement: 'topRight',
      })
    }
  }

  const { tempPassword } = useParams()
  const userEmail = auth?.email || ''

  const promeniPasswordMutation = useMutation({
    mutationFn: async (userData) => {
      await axiosPrivate.post(
        `/forgottenPassword/newPassword`,
        { oldPassword, newPassword, userEmail },
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      )
    },
    onSuccess: () => {
      navigate('/')
    },
    onError: (error) => {
      if (error.response.status === 400) {
        setIsError(true)
        setErrorMsg('Pogrešana stara lozinka.')
      }
    },
  })

  const handleChangePassword = () => {
    if (
      validOldPassword &&
      validNewPassword &&
      newPassword === confirmedNewPassword
    ) {
      promeniPasswordMutation.mutate()
    }

    if (!validOldPassword) {
      // console.log("stari password nije validan");
      setIsError(true)
      setErrorMsg('Stara lozinka nije validna.')
      return
    }
    if (!validNewPassword) {
      // console.log("novi password nije validan");
      setIsError(true)
      setErrorMsg('Nova lozinka nije validna.')
      return
    }
    if (newPassword !== confirmedNewPassword) {
      // console.log("Lozinke se ne podudaraju");
      setIsError(true)
      setErrorMsg('Lozinke se ne podudaraju.')
      return
    }
  }

  const handleSaveAllChanges = async () => {
    try {
      const tasks = []

      if (newPassword && confirmedNewPassword) {
        tasks.push(handleChangePassword())
      }

      if (teamValue && teamValue !== 'none') {
        tasks.push(handleUpdateTeam(teamValue))
      }

      if (usernameValue && usernameValue.trim() !== '') {
        tasks.push(handleUpdateUserName()) // Ispravljeno ime funkcije
      }

      const results = await Promise.allSettled(tasks)

      // Provera da li je bilo grešaka
      const hasError = results.some((result) => result.status === 'rejected')

      if (hasError) {
        throw new Error('Jedna ili više promena nisu uspešno sačuvane.')
      }

      notification.success({
        message: 'Sve promene su uspešno sačuvane.',
        placement: 'topRight',
      })

      navigate('/')
    } catch (error) {
      console.error('Greška pri čuvanju promena:', error)
      notification.error({
        message: 'Došlo je do greške pri ažuriranju podataka.',
        placement: 'topRight',
      })
    }
  }

  const profileImage = userInfoQuery?.data?.team
    ? `${SERVER_URL}/${userInfoQuery?.data?.team?.teamImage?.url}`
    : '../no_team_image.png'

  return (
    <div className={classes.mainCenter}>
      {userInfoQuery.isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className={classes.headerContainer}>
            <h1>MOJ NALOG</h1>
          </div>
          <div className={classes.containerWrapper}>
            {!isEditing ? (
              <div className={classes.container}>
                <div className={classes.left}>
                  <div className={classes.profileImageContainer}>
                    <img src={profileImage} alt={`Profile picture`} />

                    {changeUsername ? (
                      <input
                        className={classes.userNameField}
                        type='text'
                        name='userName'
                        value={usernameValue}
                        onChange={(e) => onChangeUsername(e)}
                      />
                    ) : (
                      <span>{userInfoQuery?.data?.userName}</span>
                    )}
                  </div>
                  <div className={classes.btnContainer}>
                    <button className={classes.btn} onClick={() => signout()}>
                      Izloguj se
                    </button>
                    <button className={classes.btn} onClick={setIsEditing}>
                      Izmeni Nalog
                    </button>
                  </div>
                </div>
                <div className={classes.right}>
                  <div className={classes.single}>
                    Ime i Prezime:
                    <span>{userInfoQuery?.data?.userName}</span>
                  </div>
                  <div className={classes.single}>
                    Email:
                    <span>{userInfoQuery?.data?.email}</span>
                  </div>
                  <div className={classes.single}>
                    Lozinka:
                    <span>
                      {'*'.repeat(userInfoQuery?.data?.password?.length || 12)}
                    </span>
                  </div>
                  <div className={classes.single}>
                    Registrovan od:
                    <span>{createdAt}</span>
                  </div>
                  <div className={classes.single}>
                    Plaćeno do:
                    <span>
                      {userInfoQuery?.data?.status === 'Admin' ||
                      userInfoQuery?.data?.status === 'Vip'
                        ? 'Neograničeno'
                        : paidToDate}
                    </span>
                  </div>
                  <div className={classes.single}>
                    Status:
                    <span>{userInfoQuery?.data?.status}</span>
                  </div>
                  <div className={`${classes.single} ${classes.chooseTeam}`}>
                    Navijač tima:
                    <span>{userInfoQuery?.data?.team?.name}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={classes.editContainer}>
                <div className={classes.formGroup}>
                  <div className={classes.formHeaderWrapper}>
                    {' '}
                    <div className={classes.profileImageContainerEdit}>
                      <img src={profileImage} alt={`Profile picture`} />

                      {changeUsername ? (
                        <input
                          className={classes.userNameField}
                          type='text'
                          name='userName'
                          value={usernameValue}
                          onChange={(e) => onChangeUsername(e)}
                        />
                      ) : (
                        <span>{userInfoQuery?.data?.userName}</span>
                      )}
                    </div>
                    <div
                      className={`${classes.btnContainer} ${
                        isEditing ? classes.changeProfileBtnContainer : ''
                      }`}
                    >
                      <button
                        className={classes.btn}
                        onClick={() => setIsEditing(false)}
                      >
                        Odustani
                      </button>
                      <button
                        className={classes.btn}
                        onClick={handleSaveAllChanges}
                      >
                        Sačuvaj
                      </button>
                    </div>
                  </div>

                  <div className={classes.single}>
                    <label>Ime i Prezime:</label>
                    <input
                      type='text'
                      value={usernameValue}
                      onChange={onChangeUsername}
                      className={classes.input}
                    />
                  </div>
                  <div className={classes.single}>
                    <label>Email:</label>
                    <input
                      type='text'
                      className={classes.input}
                      value={userInfoQuery?.data?.email || ''}
                      readOnly
                    />
                  </div>
                  <div className={classes.single}>
                    <label>Stara lozinka:</label>
                    <div className={classes.inputWrapper}>
                      <input
                        type={oldPasswordVisible ? 'text' : 'password'}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className={classes.input}
                      />
                      <img
                        src='/icons/seePassword.svg'
                        alt='show password'
                        className={classes.showPassword}
                        onClick={() =>
                          setOldPasswordVisible(!oldPasswordVisible)
                        }
                      />
                    </div>
                  </div>

                  <div
                    className={`${classes.single} ${classes.singleWithText}`}
                  >
                    {isError && (
                      <div className={classes.error}>
                        <p>{errorMsg}</p>
                      </div>
                    )}

                    <label>Unesite novu lozinku:</label>
                    <div>
                      <div className={classes.inputWrapper}>
                        <input
                          onChange={(e) => setNewPassword(e.target.value)}
                          className={classes.input}
                          type={newPasswordVisible ? 'text' : 'password'}
                        />
                        <img
                          src='/icons/seePassword.svg'
                          alt='show password'
                          className={classes.showPassword}
                          onClick={() =>
                            setNewPasswordVisible(!newPasswordVisible)
                          }
                        />
                      </div>
                      <p className={classes.green}>
                        Napomena: Nova lozinka mora imati minimum 8 karaktera i
                        mora sadržati bar jedno veliko slovo, bar jedno malo
                        slovo, bar jedan broj i bar jedan specijalan karakter.
                      </p>
                    </div>
                  </div>

                  <div className={classes.single}>
                    <label>Potvrdite novu lozinku:</label>
                    <div className={classes.inputWrapper}>
                      <input
                        onChange={(e) =>
                          setConfirmedNewPassword(e.target.value)
                        }
                        className={classes.input}
                        type={confirmNewPasswordVisible ? 'text' : 'password'}
                      />
                      <img
                        src='/icons/seePassword.svg'
                        alt='show password'
                        className={classes.showPassword}
                        onClick={() =>
                          setConfirmNewPasswordVisible(
                            !confirmNewPasswordVisible
                          )
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <div className={classes.single}>
                      Registrovan od:
                      <span>{createdAt}</span>
                    </div>
                    <div className={classes.single}>
                      Plaćeno do:
                      <span>
                        {userInfoQuery?.data?.status === 'Admin' ||
                        userInfoQuery?.data?.status === 'Vip'
                          ? 'Neograničeno'
                          : paidToDate}
                      </span>
                    </div>
                    <div className={classes.single}>
                      Status:
                      <span>{userInfoQuery?.data?.status}</span>
                    </div>
                  </div>
                  <div className={classes.single}>
                    <label>Izaberi tim</label>
                    <select
                      value={teamValue || ''}
                      onChange={(e) => handleChangeTeam(e.target.value)}
                      className={classes.input}
                    >
                      <option value=''>Izaberi tim</option>
                      {allTeams?.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
            <div className={classes.wrapper}>
              <div className={classes.adminButtons}>
                <h3>
                  <img src='/panel.svg' alt='Paneli' /> Paneli
                </h3>
                {(userInfoQuery?.data?.status === 'Admin' ||
                  userInfoQuery?.data?.moderator === true) && (
                  <button className={classes.admin}>
                    <Link to='/betko-admin'>Idi na admin panel</Link>
                  </button>
                )}
                {(auth?.accessAdminShop === 'approved' ||
                  userInfoQuery?.data?.status === 'Admin') && (
                  <button className={classes.admin}>
                    <Link to='/betko-shop-admin/home'>
                      Idi na shop admin panel
                    </Link>
                  </button>
                )}
              </div>
              <div>
                <LoginPageImage />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default MojNalog
