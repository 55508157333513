import React, { useRef, useState } from 'react'
import classes from './adVideoPlayer.module.css'

const AdVideoPlayer = ({ src }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !videoRef.current.muted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className={classes.videoContainer}>
      <video
        ref={videoRef}
        src={src}
        autoPlay
        loop
        muted
        playsInline
        className={classes.video}
      />

      <div className={classes.controls}>
        <div className={classes.button} onClick={(e) => { e.stopPropagation(); togglePlayPause(); }}>
          {/* temporary buttons */}
          {isPlaying ? "⏸️" : "▶️"}
        </div>
        <div className={classes.button} onClick={(e) => { e.stopPropagation(); toggleMute(); }}>
          {isMuted ? "🔇" : "🔊"}
        </div>
      </div>
    </div>
  );
};

export default AdVideoPlayer
