import { useState } from 'react'
import useAxiosPrivate from '../hooks/useAxiosPrivate'
import { useQuery } from '@tanstack/react-query'
import SinglePost from '../components/SinglePost'
import Spinner from '../components/Spinner'
import classes from './betPortal.module.css'
import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'

const BetPortal = () => {
  const [posts, setPosts] = useState([])
  const [isMsg, setIsMsg] = useState(false)
  const axiosPrivate = useAxiosPrivate()

  const getAllBetPortalPosts = async () => {
    const { data } = await axiosPrivate.get('/getAllBetPortalPosts')
    data.msg ? setIsMsg(true) : setPosts(data.result)
    !data.msg ? setIsMsg(false) : setPosts([])
    return data
  }
  const allBetPortalPostsQuery = useQuery({
    queryKey: ['infoBlok'],
    queryFn: getAllBetPortalPosts,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  })

  return (
    <LayoutWithCalcAndSideImgs title='BET PORTAL'>
      <div className={classes.container}>
        {allBetPortalPostsQuery.isLoading || posts.length === 0 ? (
          <div className={classes.spinner}>
            <Spinner />
          </div>
        ) : (
          <>
            {!isMsg ? (
              posts.map((post) => {
                return (
                  <SinglePost
                    commentNumbers={post?.commentsNumber}
                    key={post._id}
                    post={post}
                    postId={post._id}
                    type='betPortal'
                  />
                )
              })
            ) : (
              <div className={classes.msg}>
                <p>{allBetPortalPostsQuery.data.msg}</p>
              </div>
            )}
          </>
        )}
      </div>
    </LayoutWithCalcAndSideImgs>
  )
}

export default BetPortal
