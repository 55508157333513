import { useState } from 'react'
import axios from '../config/axios'
import { useQuery } from '@tanstack/react-query'
import SinglePost from '../components/SinglePost'
import Spinner from '../components/Spinner'
import Modal from 'antd/es/modal/Modal'
import classes from './korisnickiPaketiListaNew.module.css'
import { useNavigate } from 'react-router-dom'
import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'

const KorisnickiPaketiListaNew = () => {
  const [posts, setPosts] = useState([])
  const navigate = useNavigate()
  const [isMsg, setIsMsg] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const openModal = () => setIsOpen(true)
  const closeModal = () => setIsOpen(false)

  const getAllKorisnickiPaketi = async () => {
    const { data } = await axios.get('/users-packages')
    data.msg ? setIsMsg(true) : setPosts(data.result)
    !data.msg ? setIsMsg(false) : setPosts([])
    return data
  }
  const allKorisnickiPaketi = useQuery({
    queryKey: ['korisnickiPaketi'],
    queryFn: getAllKorisnickiPaketi,
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
  })
  return (
    <LayoutWithCalcAndSideImgs title={'KORISNIČKI PAKETI'}>
      <div className={classes.mainContainer}>
        <div className={classes.chosePackage}>
          <div className={classes.imgWrapper}>
            <div className={classes.imgContainer}>
              <img src='/icons/green-fire.svg' alt='korisnicki paketi' />
            </div>
            <div className={classes.textContainer}>
              <p>KAKO? BRZO I LAKO!</p>
              <span>KUPI BETKO PAKETE</span>
            </div>
          </div>
          <button onClick={openModal}>Izaberi paket</button>
        </div>
        <div className={classes.singlePostContainer}>
          {allKorisnickiPaketi.isLoading || posts.length === 0 ? (
            <div className={classes.spinner}>
              <Spinner />
            </div>
          ) : (
            <>
              {!isMsg ? (
                posts.map((post) => {
                  return (
                    <SinglePost
                      key={post._id}
                      postId={post._id}
                      post={post}
                      type='korisnickiPaketi'
                    />
                  )
                })
              ) : (
                <div className={classes.msg}>
                  <p>{allKorisnickiPaketi.data.msg}</p>
                </div>
              )}
            </>
          )}
        </div>
        <Modal open={isOpen} onCancel={closeModal} footer={null} centered>
          <div className={classes.modalBody}>
            <div className={classes.modalHeader}>
              <h2>Izaberi paket</h2>
            </div>
            <div className={classes.packageContainer}></div>
          </div>
        </Modal>
      </div>
    </LayoutWithCalcAndSideImgs>
  )
}
export default KorisnickiPaketiListaNew
