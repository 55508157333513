import React from 'react'

import classes from './textPages.module.css'

const BetkoFamily = () => {
  return (
    <div className={classes.content}>
      <div className={classes.headerContainer}>
        <h1>ŠTA JE BETKO FAMILY ?</h1>
      </div>
      <p>
        BETTING (R)EVOLUCIJA !
      </p>
      <p>
      Pridruži se našim projektima !
      Kriraj budućnost ''Betting-a'' !
      Postani deo nje !
      </p>
      <p>
        BETKO TI NUDI, DEO BETKO FAMILIJE BUDI !
      </p>
      <p>
        Sve što je potrebno jeste da nas kontaktirate ! Imamo projekte spremne za vas !
      </p>
      <p>
        INOVANTNE !
        VIRALNE !
        DRUGAČIJE !
        JEDINSTVENE !
      </p>
      <p>
        ...I do sada nigde viđene na tržištu sportskog klađenja !
      </p>
      <p>
        Ukoliko se bavite betting-om u bilo kojoj oblasti delovanja pri istom, sa našim idejnim rešenjima i sa našim projektima, uz Vašu podršku, oni će postati i Vaši !
      </p>
      <p>
        Ukoliko ste ''Igrač'', i imate ambiciju da postanete više od toga, sa Betkom se može, sve što se poželi !
      </p>
      <p>
        Ukoliko zastupate ''Sportsku kladionicu'' i želite da postanete lider svih kladionica na Balkanu, a vremenom i na celokupnom svetskom tržištu, šta čekate ? Imate li zaista šta da izgubite ?
      </p>
      <p>
        Mi znamo put do velikih stvari ! Vaše je samo da nam se javite !
      </p>
      <p>
        To možete inicijalno učiniti na chatu ''PITAJ BETKA'' u okviru našeg sajta, ili zvaničnim putem e-maila, na našu e-mail adresu :
      </p>
      <p>
        betko.podrska@gmail.com
      </p>
    </div>
  )
}

export default BetkoFamily
