import React from 'react';
import { useState, useEffect } from "react";
import axios from '../config/axios';
import classes from './adminManageAds.module.css';
import { notification } from 'antd';
import AdVideoPlayer from '../components/AdVideoPlayer';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const AdminManageAds = () => {
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const [adsList, setAdsList] = useState([]);
  const [selectedAds, setSelectedAds] = useState([null, null, null]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/get-all-adds`);
        setAdsList(response.data);
      } catch (error) {
        console.error("Fetch error:", error);
      }
    };

    const storedAds = JSON.parse(localStorage.getItem("selectedAds")) || [null, null, null];
    setSelectedAds(storedAds);

    fetchAds();
  }, [refetchTrigger]);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    console.log(file)

    const formData = new FormData();
    formData.append("file", file);

    const fileExtension = file.name.split('.').pop().toLowerCase();
    const fileType = ["mp4", "mov", "avi"].includes(fileExtension) ? "video" : "image";

    try {
        const response = await axios.post(`${SERVER_URL}/upload-adds-image-or-video`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                "type": fileType
            }
        });

        if (response.status === 201) {
            notification.success({ message: "Reklama uspešno postavljena!" });
            setRefetchTrigger((prev) => !prev);
        }
    } catch (error) {
        console.error("Upload error:", error);
        notification.error({ message: "Greška pri postavljanju reklame." });
    }
  };

  const handleDeletion = async (adId) => {
    try {
        const response = await axios.delete(`${SERVER_URL}/delete-adds-image/${adId}`);

        if (response.status === 200) {
            notification.success({ message: "Reklama uspešno obrisana!" });
            setRefetchTrigger((prev) => !prev);
        }
    } catch (error) {
        console.error("Deletion error:", error);
        notification.error({ message: "Greška pri brisanju reklame." });
    }
  };

  
  const handleSelectContainer = (index) => {
    setActiveIndex(index);
  };

  const handleSelectAd = (ad) => {
    if (activeIndex === null) return;
    const updatedAds = [...selectedAds];
    updatedAds[activeIndex] = ad;
    setSelectedAds(updatedAds);

    localStorage.setItem("selectedAds", JSON.stringify(updatedAds));

    setActiveIndex(null);
  };

  const handleRemoveSelectedAd = (index) => {
    const updatedAds = [...selectedAds];
    updatedAds[index] = null;

    setSelectedAds(updatedAds);
    localStorage.setItem("selectedAds", JSON.stringify(updatedAds));
  };

  return (
    <div className={classes.container}>
      <h1>Upravljanje reklamama: </h1>
      <div className={classes.btnContainer}>
        <label className={classes.btn}>
          Objavi sliku/video
          <input type="file" onChange={handleUpload} className={classes.hiddenInput} />
        </label>
      </div>

      <h2>Odabrane reklame:</h2>
      <div className={classes.selectedAdsContainer}>
        {selectedAds.map((ad, index) => (
          <div className={classes.adsWrapper}>
            <div
              key={index}
              className={`${classes.adContainer} ${activeIndex === index ? classes.active : ""}`}
              onClick={() => handleSelectContainer(index)}
            >
              {ad ? (
                <>
                  {ad.type === "video" ? (
                    <AdVideoPlayer className={classes.adContainer} src={`${SERVER_URL}/${ad.localPath}`} />
                  ) : (
                    <img className={classes.adContainer} src={`${SERVER_URL}/${ad.localPath}`} alt="Ad" />
                  )}
                </>
              ) : (
                <p>Odaberi reklamu</p>
              )}
              
            </div>
            <button className={classes.btn} onClick={() => handleRemoveSelectedAd(index)}>Sakrij</button>
          </div>
        ))}
      </div>
      
      <h2>Sve reklame:</h2>
      <div className={classes.selectedAdsContainer}>
        {adsList.map((ad) => (
          <div onClick={() => handleSelectAd(ad)} className={classes.adsWrapper} key={ad._id}>
            {ad.type === "video" ? (
                <AdVideoPlayer className={classes.adContainer} src={`${SERVER_URL}/${ad.localPath}`} />
              ) : (
                <img className={classes.adContainer} src={`${SERVER_URL}/${ad.localPath}`} alt="Ad" />
              )}
              <button className={classes.btn} onClick={() => handleDeletion(ad._id)}>Obriši</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminManageAds;
