import React from 'react'
import CalculatorImage from '../CalculatorImage/CalculatorImage'
import SideImages from '../SideImages/SideImages'
import classes from './LayoutWithCalcAndSideImgs.module.css'
import { useLocation } from 'react-router-dom'

const LayoutWithCalcAndSideImgs = ({ children, title }) => {
  const shouldShowBackButton =
    window.location.pathname === '/semafori' ||
    window.location.pathname === '/korisnicki_paketi'
  const isSemaforiPage = window.location.pathname === '/semafori'

  const handleBackButtonClick = () => {
    window.history.back()
  }
  return (
    <div className={`${classes.container} ${classes.infoBlockContainer}`}>
      <SideImages />
      <div
        className={`${classes.postsPageContainer} ${
          isSemaforiPage ? classes.semaforPostPageContainer : ''
        } `}
      >
        <div
          className={`${classes.pageTitleContainer} ${
            isSemaforiPage ? classes.semaforiTitleContainer : ''
          }`}
        >
          {shouldShowBackButton && (
            <button
              onClick={handleBackButtonClick}
              className={classes.backButton}
            >
              <img src='/icons/white-left-arrow.svg' alt='nazad' />
              Nazad
            </button>
          )}
          <h1 className={`${classes.pageTitle} `}>{title}</h1>
        </div>
        <div
          className={`${classes.postsAndCalculatorWrapper} ${
            isSemaforiPage ? classes.semaforpostsAndCalculatorWrapper : ''
          }`}
        >
          <div className={classes.postsContainer}>{children}</div>
          <CalculatorImage maxHeight={280} maxWidth={464} />
        </div>
      </div>
    </div>
  )
}

export default LayoutWithCalcAndSideImgs
