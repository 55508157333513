import React, { useEffect, useRef, useState } from 'react'
import classes from './SingleComment.module.css'
import { SERVER_URL } from '../../../helpers/urls'
import { useLocation, useNavigate } from 'react-router-dom'
import { dateWithHours } from '../../../utils/DateWithHours'
import { renderTextWithLinks } from '../../../utils/renderTextWithLinks'
import { RxTriangleUp } from 'react-icons/rx'
const SingleComment = ({
  item,
  isHomePage,
  setGiftCommentValue,
  setIsGiftModalVisible,
  commentIndex,
  editComment,
  editCommentValue,
  setEditCommentValue,
  showComments,
  handleShowComments,
  handleReportCommentModal,
  handleBlockUserModal,
  handleReactToComment,
  user,
  handleAddComment,
  comment,
  setComment,
  handleAnswerOnComment,
  answersParentComment,
  setAnswersParentComment,
  answersPage,
  setAnswersPage,
  handleRateComment,
  changeRatingColor,
  currentPage
}) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)
  const [showRatingOptions, setShowRatingOptions] = useState(false)
  const [averageRating, setAverageRating] = useState(0)
  const menuRef = useRef(null)
  const popupRef = useRef(null)
  const navigate = useNavigate()
  const location = useLocation()
  const isHiddenComment = location.pathname === '/rezultati_uzivo'

  const commentDate = item.editDate ? item.editDate : item.createdAt
  const cuttedInnerHtml = item?.comment.substring(0, 450)
  const innerHtml = item?.comment
  const commentLikes = +item.likes.length + +item.adminLikes
  const commentDislikes = +item.dislikes.length + +item.adminDislikes
  const alreadyLiked = !!item.likes.includes(user?._id)
  const alreadyDisliked = !!item.dislikes.includes(user?._id)

  const calculateAverageRating = (ratings) => {
    if (!ratings?.length) return 0; // Avoid division by zero
    return ratings.reduce((acc, ratingObj) => acc + ratingObj?.rating, 0) / ratings.length;
  }
  

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOptionsMenuOpen(false)
    }
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowRatingOptions(false)
    }
  }

  const handleRatingClick = async (num, item) => {
    const success = await handleRateComment({ number: num, comment: item })
    
    if (success) {
      setShowRatingOptions(false)
    
      setAverageRating(calculateAverageRating)
      const updatedRatings = [...item.ratings, {rating: num}]
      setAverageRating(calculateAverageRating(updatedRatings))
    }
  }

  const toggleMenu = (e) => {
    e.stopPropagation()
    setIsOptionsMenuOpen((prev) => !prev)
  }

  useEffect(() => {
    if (item) {
      setAverageRating(calculateAverageRating(item.ratings))
    }
  }, [item])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  console.log(answersParentComment)

  const answersShown = answersParentComment?.parent === item._id

  const hideSpans = location.pathname === '/rezultati_uzivo'
  return (
    <div
      className={`${classes.singleComment} ${
        answersShown ? classes.withAnswersShown : ''
      }`}
    >
      <div className={classes.singleCommentInner}>
      {isHomePage && currentPage === 1 && item.isPinned && (
        <div 
          className={`${classes.adminCommentMessage} ${classes.pinnedAdminComment}`}
          onClick={() => navigate(`/bet_portal/653e47fa52a098b10de971f2`)}  
        >        
          <div className='imageWrapper'>
            <img
              src='/icons/comments/commentsAdminMessage.svg'
              alt='Poruka admina'
            />
          </div>
          <div className={classes.adminCommentMessageContent}>
            <span className={classes.adminCommentTitle}>PORUKA OD BETKA</span>
            <span className={classes.adminCommentText}>{item?.adminMessage}</span>
          </div>
        </div>
      )}
      {!isHiddenComment && !item.isPinned && (
          <div className={classes.commentLinkToPost}>
            <span className={classes.commentType}>
              {item?.type?.toUpperCase().replace(/_/g, ' ')} - {item?.postTitle}
            </span>
            <button onClick={() => navigate(`/${item?.linkToPost}`)}>
              <span>Postavite komentar</span>
              <img
                src='/icons/comments/commentsArrowUp.svg'
                alt='ostavi komentar'
              />
            </button>
          </div>
        )}
        {item.adminComment && (
          <div className={classes.adminCommentMessage}>
            <div className='imageWrapper'>
              <img
                src='/icons/comments/commentsAdminMessage.svg'
                alt='Poruka admina'
              />
            </div>
            <div className={classes.adminCommentMessageContent}>
              <span className={classes.adminCommentTitle}>PORUKA OD BETKA</span>
              <span className={classes.adminCommentText}>
                {/* {'JOS MALO I PRELAZIS IGRICU SAMO TAKO NASTAVI!'} */}
                {item.adminComment}
              </span>
            </div>
          </div>
        )}
        <div className={classes.commentHeader}>
          <div className={classes.logoWrapper}>
            {item?.owner?.team && item?.owner?.team?.teamImage ? (
              <img
                src={`${SERVER_URL}/${item?.owner?.team?.teamImage?.url}`}
                alt='Dres Tima'
              />
            ) : (
              <img src='../teamPlaceholder.png' alt='Dres Tima' />
            )}
          </div>
          <div className={classes.userInfo}>
            <span className={classes.userName}>{item?.owner?.userName}</span>
            <span className={classes.userRole}>
              <div className={classes.userIcon}>
                <img src='/icons/comments/user.svg' alt='korisnik' />
              </div>
              <span>
                {item?.owner?.status === 'Admin'
                  ? 'Administrator'
                  : item?.owner?.team?.name}{' '}
              </span>
            </span>
          </div>
          <div className={classes.commentHeaderBtns} ref={menuRef}>
            <button
              className={classes.tipsterBtn}
              onClick={() =>
                navigate(`/mister_tipster/profil?user=${item?.owner?._id}`)
              }
            >
              <img
                src='/icons/comments/commentsTipster.svg'
                alt='Mister tipster'
              />
            </button>

            <button
              onClick={(e) => {
                setGiftCommentValue(item?.gift)
                setIsGiftModalVisible(true)
              }}
            >
              <img src='/icons/comments/commentsGift.svg' alt='poklon' />
            </button>
            <button onClick={(e) => toggleMenu(e)}>
              <img
                src='/icons/comments/commentsMoreOptions.svg'
                alt='vise opcija'
              />
            </button>
            {isOptionsMenuOpen && (
              <ul className={classes.optionsMenu}>
                <li>
                  <button
                    disabled={item?.reports?.includes(user?._id)}
                    onClick={() => handleReportCommentModal(item)}
                  >
                    <img
                      src='/icons/comments/commentsReport.svg'
                      alt='prijavi korisnika'
                    />
                    <span>Prijavi korisnika</span>
                  </button>
                </li>
                <li>
                  <button
                    disabled={item?.owner?.blockedBy?.some(
                      (owner) => owner.id === user?._id
                    )}
                    onClick={() => handleBlockUserModal(item?.owner)}
                  >
                    <img
                      src='/icons/comments/commentsBlockUser.svg'
                      alt='blokiraj korisnika'
                    />
                    <span>Blokiraj korisnika</span>
                  </button>
                </li>
                <li className={classes.idLi}>
                  <button>
                    <span>ID: {item?.owner?._id}</span>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={classes.commentTime}>
          Komentar br. {commentIndex} • {dateWithHours(commentDate)}
        </div>
        <div className={classes.commentBody}>
          {editComment && editCommentValue._id === item._id ? (
            <textarea
              name='editComment'
              value={editCommentValue?.comment || ''}
              rows={5}
              onChange={(e) =>
                setEditCommentValue((prev) => {
                  return {
                    ...prev,
                    comment: e.target.value,
                  }
                })
              }
            />
          ) : showComments.includes(item._id) ? (
            <div
              className={classes.commentContent}
              dangerouslySetInnerHTML={renderTextWithLinks(innerHtml)}
            />
          ) : (
            <div
              className={classes.commentContent}
              dangerouslySetInnerHTML={renderTextWithLinks(cuttedInnerHtml)}
            />
          )}
        </div>
        <div className={classes.commentShowMore}>
          {item?.comment?.length >= 170 && (
            <button type='button' className={classes.showMoreButton} onClick={() => handleShowComments(item)}>
              {showComments.includes(item._id) ? (
                <span>...Sakrij</span>
              ) : (
                <span>...Prikaži još</span>
              )}
            </button>
          )}
        </div>
        <div className={classes.commentFeedback}>
          <div className={classes.feedbackLike}>
            <div>
              <img src='/icons/comments/commentsLike.svg' alt='like' />
            </div>
            <span>{commentLikes}</span>
          </div>
          <div className={classes.feedbackDislike}>
            <div>
              <img src='/icons/comments/commentsDislike.svg' alt='dislike' />
            </div>
            <span>{commentDislikes}</span>
          </div>
          <div className={classes.feedbackRating}>
            <div className={changeRatingColor({ averageRating: averageRating })}>
              <img src='/icons/comments/commentsRating.svg' alt='rating' />
            </div>
            <span>{averageRating}</span>
          </div>
        </div>
        {/* TODO: CHANGE STYLE FOR REACTIONS */}
        <div className={classes.commentFeedbackOptions}>
          <button
            className={alreadyLiked ? classes.alreadyLiked : ''}
            disabled={alreadyLiked}
            onClick={(e) =>
              handleReactToComment({
                type: 'like',
                action: { payload: item },
              })
            }
          >
            <img src='/icons/comments/commentsLike.svg' alt='like' />
            {!hideSpans && <span>Sviđa mi se</span>}
          </button>
          <button
            className={alreadyDisliked ? classes.alreadyDisliked : ''}
            disabled={alreadyDisliked}
            onClick={(e) =>
              handleReactToComment({
                type: 'dislike',
                action: { payload: item },
              })
            }
          >
            <img src='/icons/comments/commentsDislike.svg' alt='dislike' />
            {!hideSpans && <span>Ne sviđa mi se</span>}
          </button>
          <button className={classes.ratingWrapper} ref={popupRef}
            onClick={() => setShowRatingOptions(!showRatingOptions)}
          >
            <img src='/icons/comments/commentsRating.svg' alt='rating' />
            {showRatingOptions && (
            <div className={classes.ratingPopup}>
              {[1, 2, 3, 4, 5].map((num) => (
                <span
                  key={num}
                  onClick={() => handleRatingClick(num, item)}
                  className={classes.ratingNumber}
                >
                  {num}
                </span>
              ))}
            </div>
          )}
            {!hideSpans && <span>Oceni</span>}
          </button>
        </div>
      </div>
      <div className={classes.commentInputContainer}>
        <div
          className={classes.textAreaWrapper}
          onClick={(e) => handleAnswerOnComment({ e: e, item: item })}
        >
          <div className={classes.messageIcon}>
            <img src='/icons/comments/message.svg' alt='Poruka' />
          </div>
          <textarea
            placeholder='Unesi odgovor'
            id={`${item._id}-textarea`}
            onChange={(e) =>
              setComment((curr) => ({ ...curr, [item?._id]: e.target.value }))
            }
            value={comment[item._id] || ''}
          />
          <button
            className={classes.sendIcon}
            onClick={(e) =>
              handleAddComment({ e: e, type: 'answer', commentId: item._id })
            }
          >
            <img src='/icons/comments/send.svg' alt='Posalji poruku' />
          </button>
        </div>
        {console.log(answersPage)}
        {item?.repliesCount > 0 && !answersShown && (
          <button
            onClick={() =>
              setAnswersParentComment({
                post: item.post,
                parent: item._id,
              })
            }
            className={classes.showCommentsBtn}
          >
            <span>Učitaj još ({item?.repliesCount}) odgovora</span>
          </button>
        )}
        {answersParentComment?.parent === item._id && answersPage > 1 && (
          <div
            onClick={() => setAnswersPage((page) => page - 1)}
            className={`${classes.showAnswersContainer} ${classes.showPrevAnswers}`}
          >
            <div>
              <RxTriangleUp />
            </div>
            <span>Prikaži prethodne odgovore</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default SingleComment
