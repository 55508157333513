import LayoutWithCalcAndSideImgs from '../components/Redesign/LayoutWithCalcAndSideImgs/LayoutWithCalcAndSideImgs'
import classes from './semafori.module.css'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import AuthContext from '../context/AuthProvider'
const links = [
  {
    label: 'Betko Top predlozi',
    url: '/info_blok/653f22fb52a098b10de97cd7',
  },
  {
    label: 'Klasik Sistemi',
    url: '/info_blok/653f219b52a098b10de97c84',
  },
  {
    label: 'BETKO Specijal',
    url: '/info_blok/653f11b752a098b10de979d7',
  },
  {
    label: 'Kamikaza predlozi',
    url: '/info_blok/656e60f9b3cfb2837182da1b',
  },
  {
    label: 'Kod BETKA za džabe',
    url: '/info_blok/655d648ae0dc5f730f30aef8',
  },
  {
    label: 'Kaskader predlozi',
    url: '/info_blok/653f09bb52a098b10de9785c',
  },
  {
    label: 'Krejzi sistemi',
    url: '/info_blok/653f112952a098b10de979bc',
  },
  {
    label: 'BETKO live predlozi',
    url: '/info_blok/653f0db152a098b10de9790a',
  },
  {
    label: 'BETKO premium informacije',
    url: '/info_blok/653f182c52a098b10de97aab',
  },
  {
    label: 'Korisnički paketi',
    url: '/korisnicki_paketi',
  },
  {
    label: 'Kamikaza predlozi',
    url: '/info_blok/656e60f9b3cfb2837182da1b',
  },
  {
    label: 'BETKO stratgije',
    url: '/info_blok/653f1f4c52a098b10de97c0e',
  },
  {
    label: 'BETKO live strategije',
    url: '/info_blok/653f094752a098b10de97845',
  },
  {
    label: 'BETKO kviz otvori i osvoji',
    url: '/info_blok/65629574f4ca9761eb52c686',
  },
  {
    label: 'Pitaj BETKA',
    url: 'https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F100090673000468%2F',
    target: '_blank',
  },
]

const Semafori = () => {
  const navigate = useNavigate()
  const { setLogin, auth } = useContext(AuthContext)

  return (
    <>
      <div className={classes.bgContainer}>
        {' '}
        <LayoutWithCalcAndSideImgs title='SEMAFOR'>
          <div className={classes.container}>
            <div className={classes.backButtonContainer}>
              <button
                onClick={() => navigate(-1)}
                className={classes.backButton}
              >
                <img src='/icons/white-left-arrow.svg' alt='nazad' />
                Nazad
              </button>
            </div>
            <div className={classes.downloadContainer}>
              <p>Uloguj se i preuzmi besplatan sadržaj!</p>
              <button
                className={classes.downloadButton}
                onClick={() => {
                  const downloadLink = auth?._id
                    ? 'https://drive.google.com/drive/folders/1ROB8DtVQxaj-GWq5AvZv86VfXfP7wHon'
                    : '/registracija_i_logovanje'
                  window.open(downloadLink, '_blank')
                }}
              >
                <img src='/icons/download.svg' alt='preuzmi besplatno' />
                PREUZMI BESPLATNO
              </button>
            </div>
            <div className={classes.buttonContainer}>
              {links.map((link, index) => (
                <button
                  className={classes.button}
                  key={index}
                  onClick={() => {
                    if (link.url.startsWith('http')) {
                      window.open(link.url, link.target || '_self')
                    } else {
                      window.location.href = link.url
                    }
                  }}
                >
                  <img
                    src='/icons/link-arrow.svg'
                    alt='preuzmi besplatno'
                    className={classes.linkArrow}
                  />
                  {link.label}
                </button>
              ))}
            </div>
          </div>
        </LayoutWithCalcAndSideImgs>
      </div>
    </>
  )
}
export default Semafori
