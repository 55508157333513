import React from 'react'
import classes from './textPages.module.css'

const BetkoPlus = () => {
  return (
    <div className={classes.content}>
      <div className={classes.headerContainer}>
        <h1>NA BETKU TE ČEKA SPORTSKO TIPSTERSKA ''EUREKA''!</h1>
      </div>
      <p>
        Na ''BETKO PLUS'' te meniju čekaju mnoge inovacije, 
        u oblasti tipovanja, zabave i tvoje rekreacije.
      </p>
      <p>
        Na ovom mestu će sa nama, mnogi naši projekti ugledati svetlost dana! 
        Jedan po jedan, od kojih je svaki vredan!
      </p>
      <p>
        POSEĆUJ SAJT NAŠ, OBAVESTIĆEMO TE DA ZNAŠ!
      </p>
      <p>
        Na početnoj stranici platforme ove, 
        javićemo ti uvek, 
        kada te ''BETKO PLUS'' pozove! 
      </p>
      <p>
        Tvoje je samo da se javiš, 
        i uz ''BETKO PLUS'',
        plus na plus da praviš!
      </p>
    </div>
  )
}

export default BetkoPlus
