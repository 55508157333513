import React from 'react'
import classes from './loginPageImage.module.css'

const LoginPageImage = ({ maxHeight = 280, maxWidth = 343 }) => {
  return (
    <div
      className={classes.loginPageImage}
      style={{ maxWidth: maxWidth, maxHeight: maxHeight }}
    >
      <img
        src={maxWidth === 343 ? '/login-image-big-new.jpg' : ''}
        alt='LoginImage'
      />
    </div>
  )
}
export default LoginPageImage
