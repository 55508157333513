import { useNavigate, useLocation } from 'react-router-dom'
import classes from './betKursButtons.module.css'
import { useState, useEffect } from 'react'
import Axios from 'axios'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetKurs = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [items, setItems] = useState([])
  const [isListVisible, setIsListVisible] = useState(false)

  useEffect(() => {
    fetchItems()
  }, [])

  const fetchItems = async () => {
    try {
      const response = await Axios.get(`${SERVER_URL}/betcourses-menu`)
      setItems(response.data)
    } catch (error) {
      console.error('Error fetching items:', error)
    }
  }

  const toggleListVisibility = () => {
    setIsListVisible((prevState) => !prevState)
  }

  const handleItemClick = (id, route) => {
    setIsListVisible(false)
    navigate(route)
  }

  const isItemSelected = (id) => {
    if (id === 'landing') {
      return location.pathname === '/bet_kurs'
    }

    return location.pathname.includes(id)
  }

  return (
    <>
      <div className={classes.toggleButtonContainer}>
        <button onClick={toggleListVisibility} className={classes.toggleButton}>
          {isListVisible ? 'Zatvori listu kurseva' : 'Pogledaj listu kurseva'}
        </button>
      </div>
      <div
        className={`${classes.container} ${
          isListVisible ? classes.visible : ''
        }`}
      >
        <ul>
          <h3 className={classes.liTitle}>BETKO KURS</h3>

          <li
            onClick={() => handleItemClick('landing', '/bet_kurs')}
            className={`${classes.liItem} ${
              isItemSelected('landing') ? classes.selected : ''
            }`}
          >
            Uvod u kurs
          </li>

          {items &&
            items.length > 0 &&
            items.map((item, index) => (
              <div key={index}>
                <li className={classes.liTitle}>
                  <p style={{ margin: '0', padding: '0' }}>{item.title}</p>
                </li>
                {item.items &&
                  item.items.length > 0 &&
                  item.items.map((courseitem, courseindex) => (
                    <li
                      key={courseindex}
                      onClick={() =>
                        handleItemClick(
                          courseitem._id,
                          `/bet_kurs/${courseitem._id}`
                        )
                      }
                      className={`${classes.liItem} ${
                        isItemSelected(courseitem._id) ? classes.selected : ''
                      }`}
                    >
                      {courseitem.title}
                    </li>
                  ))}
              </div>
            ))}
        </ul>
      </div>
    </>
  )
}

export default BetKurs
