import React, { useEffect, useRef, useState } from 'react'
import classes from './SingleAnswerComment.module.css'
import { SERVER_URL } from '../../../helpers/urls'
import { useNavigate } from 'react-router-dom'
import { dateWithHours } from '../../../utils/DateWithHours'
import { renderTextWithLinks } from '../../../utils/renderTextWithLinks'
const SingleAnswerComment = ({
  item,
  isHomePage,
  setGiftCommentValue,
  setIsGiftModalVisible,
  parentIndex,
  editComment,
  editCommentValue,
  setEditCommentValue,
  showComments,
  handleShowComments,
  handleReportCommentModal,
  handleBlockUserModal,
  handleReactToComment,
  user,
  handleAddComment,
  comment,
  setComment,
  handleAnswerOnComment,
  answersParentComment,
  setAnswersParentComment,
}) => {
  const [isOptionsMenuOpen, setIsOptionsMenuOpen] = useState(false)
  const menuRef = useRef(null)
  const navigate = useNavigate()

  const commentDate = item.editDate ? item.editDate : item.createdAt
  const cuttedInnerHtml = item?.comment.substring(0, 450)
  const innerHtml = item?.comment
  const commentLikes = +item.likes.length + +item.adminLikes
  const commentDislikes = +item.dislikes.length + +item.adminDislikes
  const alreadyLiked = !!item.likes.includes(user?._id)
  const alreadyDisliked = !!item.dislikes.includes(user?._id)

  const averageRating =
    item?.ratings?.reduce((acc, ratingObj) => acc + ratingObj?.rating, 0) /
    (item?.ratings?.length || 1)

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOptionsMenuOpen(false)
    }
  }

  const toggleMenu = (e) => {
    e.stopPropagation()
    setIsOptionsMenuOpen((prev) => !prev)
  }

  const focusParentTextArea = () => {
    const textAreaId = answersParentComment.parent + '-textarea'
    const textarea = document.getElementById(textAreaId)
    if (textarea) {
      textarea.focus()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className={classes.singleAnswerComment}>
      <div className={classes.divider}></div>
      <div className={classes.singleAnswerCommentInner}>
        <div className={classes.commentHeader}>
          <div className={classes.logoWrapper}>
            {item?.owner?.team && item?.owner?.team?.teamImage ? (
              <img
                src={`${SERVER_URL}/${item?.owner?.team?.teamImage?.url}`}
                alt='Dres Tima'
              />
            ) : (
              <img src='../teamPlaceholder.png' alt='Dres Tima' />
            )}
          </div>
          <div className={classes.userInfo}>
            <span className={classes.userName}>{item?.owner?.userName}</span>
            <span className={classes.userRole}>
              <div className={classes.userIcon}>
                <img src='/icons/comments/user.svg' alt='korisnik' />
              </div>
              <span>
                {item?.owner?.status === 'Admin'
                  ? 'Administrator'
                  : item?.owner?.team?.name}{' '}
              </span>
            </span>
          </div>
          <div className={classes.commentHeaderBtns} ref={menuRef}>
            <button
              className={classes.tipsterBtn}
              onClick={() =>
                navigate(`/mister_tipster/profil?user=${item?.owner?._id}`)
              }
            >
              <img
                src='/icons/comments/commentsTipster.svg'
                alt='Mister tipster'
              />
            </button>

            <button
              onClick={(e) => {
                setGiftCommentValue(item?.gift)
                setIsGiftModalVisible(true)
              }}
            >
              <img src='/icons/comments/commentsGift.svg' alt='poklon' />
            </button>
            <button onClick={(e) => toggleMenu(e)}>
              <img
                src='/icons/comments/commentsMoreOptions.svg'
                alt='vise opcija'
              />
            </button>
            {isOptionsMenuOpen && (
              <ul className={classes.optionsMenu}>
                <li>
                  <button
                    disabled={item?.reports?.includes(user?._id)}
                    onClick={() => handleReportCommentModal(item)}
                  >
                    <img
                      src='/icons/comments/commentsReport.svg'
                      alt='prijavi korisnika'
                    />
                    <span>Prijavi korisnika</span>
                  </button>
                </li>
                <li>
                  <button
                    disabled={item?.owner?.blockedBy?.some(
                      (owner) => owner.id === user?._id
                    )}
                    onClick={() => handleBlockUserModal(item?.owner)}
                  >
                    <img
                      src='/icons/comments/commentsBlockUser.svg'
                      alt='blokiraj korisnika'
                    />
                    <span>Blokiraj korisnika</span>
                  </button>
                </li>
                <li className={classes.idLi}>
                  <button>
                    <span>ID: {item?.owner?._id}</span>
                  </button>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className={classes.commentTime}>
          Odgovor na br. {parentIndex} • {dateWithHours(commentDate)}
        </div>
        {item.adminComment && (
          <div className={classes.adminCommentMessage}>
            <div className='imageWrapper'>
              <img
                src='/icons/comments/commentsAdminMessage.svg'
                alt='Poruka admina'
              />
            </div>
            <div className={classes.adminCommentMessageContent}>
              <span className={classes.adminCommentTitle}>PORUKA OD BETKA</span>
              <span className={classes.adminCommentText}>
                {/* {'JOS MALO I PRELAZIS IGRICU SAMO TAKO NASTAVI!'} */}
                {item.adminComment}
              </span>
            </div>
          </div>
        )}
        <div className={classes.commentBody}>
          {editComment && editCommentValue._id === item._id ? (
            <textarea
              name='editComment'
              value={editCommentValue?.comment || ''}
              rows={5}
              onChange={(e) =>
                setEditCommentValue((prev) => {
                  return {
                    ...prev,
                    comment: e.target.value,
                  }
                })
              }
            />
          ) : showComments.includes(item._id) ? (
            <div
              className={classes.commentContent}
              dangerouslySetInnerHTML={renderTextWithLinks(innerHtml)}
            />
          ) : (
            <div
              className={classes.commentContent}
              dangerouslySetInnerHTML={renderTextWithLinks(cuttedInnerHtml)}
            />
          )}
        </div>
        <div className={classes.commentShowMore}>
          {item?.comment?.length >= 170 && (
            <button type='button' onClick={() => handleShowComments(item)}>
              {showComments.includes(item._id) ? (
                <span>...Sakrij</span>
              ) : (
                <span>...Prikaži još</span>
              )}
            </button>
          )}
        </div>
        <div className={classes.commentFeedbackOptions}>
          <div className={classes.feedbackBtnsWrapper}>
            <button
              disabled={alreadyLiked}
              onClick={(e) =>
                handleReactToComment({
                  type: 'like',
                  action: { payload: item },
                })
              }
            >
              <img
                src={`/icons/comments/commentsLike${
                  alreadyLiked ? 'Filled' : ''
                }.svg`}
                alt='like'
              />

              <span>{commentLikes}</span>
            </button>
            <button
              disabled={alreadyDisliked}
              onClick={(e) =>
                handleReactToComment({
                  type: 'dislike',
                  action: { payload: item },
                })
              }
            >
              <img
                src={`/icons/comments/commentsDislike${
                  alreadyDisliked ? 'Filled' : ''
                }.svg`}
                alt='dislike'
              />
              <span>{commentDislikes}</span>
            </button>
            <button>
              <img
                src={`/icons/comments/commentsRating${
                  averageRating > 0 ? 'Filled' : ''
                }.svg`}
                alt='rating'
              />
              <span>{averageRating > 0 ? averageRating : ''}</span>
            </button>
          </div>
          <button className={classes.responseBtn} onClick={focusParentTextArea}>
            Odgovori
          </button>
        </div>
      </div>
    </div>
  )
}

export default SingleAnswerComment
